{
  "main": {
    "en": {
      "identity": {
        "version": {
          "_cldrVersion": "44.1"
        },
        "language": "en"
      },
      "localeDisplayNames": {
        "territories": {
          "001": "world",
          "002": "Africa",
          "003": "North America",
          "005": "South America",
          "009": "Oceania",
          "011": "Western Africa",
          "013": "Central America",
          "014": "Eastern Africa",
          "015": "Northern Africa",
          "017": "Middle Africa",
          "018": "Southern Africa",
          "019": "Americas",
          "021": "Northern America",
          "029": "Caribbean",
          "030": "Eastern Asia",
          "034": "Southern Asia",
          "035": "Southeast Asia",
          "039": "Southern Europe",
          "053": "Australasia",
          "054": "Melanesia",
          "057": "Micronesian Region",
          "061": "Polynesia",
          "142": "Asia",
          "143": "Central Asia",
          "145": "Western Asia",
          "150": "Europe",
          "151": "Eastern Europe",
          "154": "Northern Europe",
          "155": "Western Europe",
          "202": "Sub-Saharan Africa",
          "419": "Latin America",
          "AC": "Ascension Island",
          "AD": "Andorra",
          "AE": "United Arab Emirates",
          "AF": "Afghanistan",
          "AG": "Antigua & Barbuda",
          "AI": "Anguilla",
          "AL": "Albania",
          "AM": "Armenia",
          "AO": "Angola",
          "AQ": "Antarctica",
          "AR": "Argentina",
          "AS": "American Samoa",
          "AT": "Austria",
          "AU": "Australia",
          "AW": "Aruba",
          "AX": "Åland Islands",
          "AZ": "Azerbaijan",
          "BA": "Bosnia & Herzegovina",
          "BA-alt-short": "Bosnia",
          "BB": "Barbados",
          "BD": "Bangladesh",
          "BE": "Belgium",
          "BF": "Burkina Faso",
          "BG": "Bulgaria",
          "BH": "Bahrain",
          "BI": "Burundi",
          "BJ": "Benin",
          "BL": "St. Barthélemy",
          "BM": "Bermuda",
          "BN": "Brunei",
          "BO": "Bolivia",
          "BQ": "Caribbean Netherlands",
          "BR": "Brazil",
          "BS": "Bahamas",
          "BT": "Bhutan",
          "BV": "Bouvet Island",
          "BW": "Botswana",
          "BY": "Belarus",
          "BZ": "Belize",
          "CA": "Canada",
          "CC": "Cocos (Keeling) Islands",
          "CD": "Congo - Kinshasa",
          "CD-alt-variant": "Congo (DRC)",
          "CF": "Central African Republic",
          "CG": "Congo - Brazzaville",
          "CG-alt-variant": "Congo (Republic)",
          "CH": "Switzerland",
          "CI": "Côte d’Ivoire",
          "CI-alt-variant": "Ivory Coast",
          "CK": "Cook Islands",
          "CL": "Chile",
          "CM": "Cameroon",
          "CN": "China",
          "CO": "Colombia",
          "CP": "Clipperton Island",
          "CQ": "Sark",
          "CR": "Costa Rica",
          "CU": "Cuba",
          "CV": "Cape Verde",
          "CV-alt-variant": "Cabo Verde",
          "CW": "Curaçao",
          "CX": "Christmas Island",
          "CY": "Cyprus",
          "CZ": "Czechia",
          "CZ-alt-variant": "Czech Republic",
          "DE": "Germany",
          "DG": "Diego Garcia",
          "DJ": "Djibouti",
          "DK": "Denmark",
          "DM": "Dominica",
          "DO": "Dominican Republic",
          "DZ": "Algeria",
          "EA": "Ceuta & Melilla",
          "EC": "Ecuador",
          "EE": "Estonia",
          "EG": "Egypt",
          "EH": "Western Sahara",
          "ER": "Eritrea",
          "ES": "Spain",
          "ET": "Ethiopia",
          "EU": "European Union",
          "EZ": "Eurozone",
          "FI": "Finland",
          "FJ": "Fiji",
          "FK": "Falkland Islands",
          "FK-alt-variant": "Falkland Islands (Islas Malvinas)",
          "FM": "Micronesia",
          "FO": "Faroe Islands",
          "FR": "France",
          "GA": "Gabon",
          "GB": "United Kingdom",
          "GB-alt-short": "UK",
          "GD": "Grenada",
          "GE": "Georgia",
          "GF": "French Guiana",
          "GG": "Guernsey",
          "GH": "Ghana",
          "GI": "Gibraltar",
          "GL": "Greenland",
          "GM": "Gambia",
          "GN": "Guinea",
          "GP": "Guadeloupe",
          "GQ": "Equatorial Guinea",
          "GR": "Greece",
          "GS": "South Georgia & South Sandwich Islands",
          "GT": "Guatemala",
          "GU": "Guam",
          "GW": "Guinea-Bissau",
          "GY": "Guyana",
          "HK": "Hong Kong SAR China",
          "HK-alt-short": "Hong Kong",
          "HM": "Heard & McDonald Islands",
          "HN": "Honduras",
          "HR": "Croatia",
          "HT": "Haiti",
          "HU": "Hungary",
          "IC": "Canary Islands",
          "ID": "Indonesia",
          "IE": "Ireland",
          "IL": "Israel",
          "IM": "Isle of Man",
          "IN": "India",
          "IO": "British Indian Ocean Territory",
          "IO-alt-biot": "British Indian Ocean Territory",
          "IO-alt-chagos": "Chagos Archipelago",
          "IQ": "Iraq",
          "IR": "Iran",
          "IS": "Iceland",
          "IT": "Italy",
          "JE": "Jersey",
          "JM": "Jamaica",
          "JO": "Jordan",
          "JP": "Japan",
          "KE": "Kenya",
          "KG": "Kyrgyzstan",
          "KH": "Cambodia",
          "KI": "Kiribati",
          "KM": "Comoros",
          "KN": "St. Kitts & Nevis",
          "KP": "North Korea",
          "KR": "South Korea",
          "KW": "Kuwait",
          "KY": "Cayman Islands",
          "KZ": "Kazakhstan",
          "LA": "Laos",
          "LB": "Lebanon",
          "LC": "St. Lucia",
          "LI": "Liechtenstein",
          "LK": "Sri Lanka",
          "LR": "Liberia",
          "LS": "Lesotho",
          "LT": "Lithuania",
          "LU": "Luxembourg",
          "LV": "Latvia",
          "LY": "Libya",
          "MA": "Morocco",
          "MC": "Monaco",
          "MD": "Moldova",
          "ME": "Montenegro",
          "MF": "St. Martin",
          "MG": "Madagascar",
          "MH": "Marshall Islands",
          "MK": "North Macedonia",
          "ML": "Mali",
          "MM": "Myanmar (Burma)",
          "MM-alt-short": "Myanmar",
          "MN": "Mongolia",
          "MO": "Macao SAR China",
          "MO-alt-short": "Macao",
          "MP": "Northern Mariana Islands",
          "MQ": "Martinique",
          "MR": "Mauritania",
          "MS": "Montserrat",
          "MT": "Malta",
          "MU": "Mauritius",
          "MV": "Maldives",
          "MW": "Malawi",
          "MX": "Mexico",
          "MY": "Malaysia",
          "MZ": "Mozambique",
          "NA": "Namibia",
          "NC": "New Caledonia",
          "NE": "Niger",
          "NF": "Norfolk Island",
          "NG": "Nigeria",
          "NI": "Nicaragua",
          "NL": "Netherlands",
          "NO": "Norway",
          "NP": "Nepal",
          "NR": "Nauru",
          "NU": "Niue",
          "NZ": "New Zealand",
          "NZ-alt-variant": "Aotearoa New Zealand",
          "OM": "Oman",
          "PA": "Panama",
          "PE": "Peru",
          "PF": "French Polynesia",
          "PG": "Papua New Guinea",
          "PH": "Philippines",
          "PK": "Pakistan",
          "PL": "Poland",
          "PM": "St. Pierre & Miquelon",
          "PN": "Pitcairn Islands",
          "PR": "Puerto Rico",
          "PS": "Palestinian Territories",
          "PS-alt-short": "Palestine",
          "PT": "Portugal",
          "PW": "Palau",
          "PY": "Paraguay",
          "QA": "Qatar",
          "QO": "Outlying Oceania",
          "RE": "Réunion",
          "RO": "Romania",
          "RS": "Serbia",
          "RU": "Russia",
          "RW": "Rwanda",
          "SA": "Saudi Arabia",
          "SB": "Solomon Islands",
          "SC": "Seychelles",
          "SD": "Sudan",
          "SE": "Sweden",
          "SG": "Singapore",
          "SH": "St. Helena",
          "SI": "Slovenia",
          "SJ": "Svalbard & Jan Mayen",
          "SK": "Slovakia",
          "SL": "Sierra Leone",
          "SM": "San Marino",
          "SN": "Senegal",
          "SO": "Somalia",
          "SR": "Suriname",
          "SS": "South Sudan",
          "ST": "São Tomé & Príncipe",
          "SV": "El Salvador",
          "SX": "Sint Maarten",
          "SY": "Syria",
          "SZ": "Eswatini",
          "SZ-alt-variant": "Swaziland",
          "TA": "Tristan da Cunha",
          "TC": "Turks & Caicos Islands",
          "TD": "Chad",
          "TF": "French Southern Territories",
          "TG": "Togo",
          "TH": "Thailand",
          "TJ": "Tajikistan",
          "TK": "Tokelau",
          "TL": "Timor-Leste",
          "TL-alt-variant": "East Timor",
          "TM": "Turkmenistan",
          "TN": "Tunisia",
          "TO": "Tonga",
          "TR": "Türkiye",
          "TR-alt-variant": "Turkey",
          "TT": "Trinidad & Tobago",
          "TV": "Tuvalu",
          "TW": "Taiwan",
          "TZ": "Tanzania",
          "UA": "Ukraine",
          "UG": "Uganda",
          "UM": "U.S. Outlying Islands",
          "UN": "United Nations",
          "UN-alt-short": "UN",
          "US": "United States",
          "US-alt-short": "US",
          "UY": "Uruguay",
          "UZ": "Uzbekistan",
          "VA": "Vatican City",
          "VC": "St. Vincent & Grenadines",
          "VE": "Venezuela",
          "VG": "British Virgin Islands",
          "VI": "U.S. Virgin Islands",
          "VN": "Vietnam",
          "VU": "Vanuatu",
          "WF": "Wallis & Futuna",
          "WS": "Samoa",
          "XA": "Pseudo-Accents",
          "XB": "Pseudo-Bidi",
          "XK": "Kosovo",
          "YE": "Yemen",
          "YT": "Mayotte",
          "ZA": "South Africa",
          "ZM": "Zambia",
          "ZW": "Zimbabwe",
          "ZZ": "Unknown Region"
        }
      }
    }
  }
}
